export const methods = {
  async reload() {
    this.loading = true

    this.actionItemID = this.$route.params.id

    console.debug(this.mailingID + ', actionItemID=' + this.actionItemID)

    this.loading = false
  },

  returnToParent() {
    console.debug('returnToParent=' + this.mailingID)

    this.$router.push({
      name: 'actionItems',
      params: {
        hoaID: this.hoaId,
        sourceBoardPackageID: null
      }
    })
  }
}
