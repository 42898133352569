<template>
  <PageContentLayoutOnly role="region" action-items-details>
    <section class="section card">
      <div class="level pl-5 pr-5">
        <div class="pt-5 level-left level-item title">{{ $t('actionItems.title') }}</div>
        <div class="level-right">
          <div class="level-item">
            <svg
              width="30"
              height="30"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              ></path>
            </svg>
          </div>
          <div class="level-item">
            <b-button label="Back to Action Items" @click.prevent="returnToParent()" />
          </div>
        </div>
      </div>

      <LinksMenu
        aria-label="Action Item tabs"
        :tabs="tabs"
        prefix="actionitem."
        :style="{
          paddingLeft: 0
        }"
        :params="{
          id: $route.params.id
        }"
      >
        <section :style="{ overflowX: 'auto', flexGrow: 2 }">
          <SlideTransition :name="transitionName">
            <keep-alive>
              <router-view :key="$route.name" />
            </keep-alive>
          </SlideTransition>
        </section>
      </LinksMenu>

      <!-- tabbable sections -->
      <!-- <div id="tabs">
        <div class="card-content columns" :style="styles.mainContainer">
          <div class="column is-2">
            <DetailsMenu
              aria-label="Action Item tabs"
              :tabs="tabs"
              prefix="actionitem."
              :params="{
                id: $route.params.id
              }"
            />
          </div>
          <div class="column is-10" style="overflow: hidden; position: relative">
            <SlideTransition :name="transitionName">
              <router-view />
            </SlideTransition>
          </div>
        </div>
      </div> -->
    </section>
  </PageContentLayoutOnly>
</template>

<script>
import SlideTransition from '@/components/transitions/Slide.vue'
import LinksMenu from '@/components/menus/Links'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { watch } from './keys/watch'

export default {
  components: {
    LinksMenu,
    SlideTransition,
    PageContentLayoutOnly
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  watch,

  mounted() {
    this.reload()
  },

  methods,

  i18n: {
    messages: {
      en: { actionItems: { title: 'Action Item Detail' } }
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-avatar {
  margin: 1em auto 2em;
}

.card {
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.05) !important;

  .card {
    border: 0 !important;
  }
}

.avatar-img {
  border: 1px solid #e7e8f2;
  padding: 0.25em;
}

.address-card {
  h5,
  h6 {
    margin: 0;
  }
}

.details-img {
  img {
    border-radius: 0.5em;
  }
}
</style>
